<template>
   <v-layout align-start mt-4>
      <v-flex>
         <v-snackbar
            v-model="snackbar"
            :color="snackColor"
            :right="true"
            :bottom="true"
            :timeout="4000"
         >
            {{ snackText }}
            <template #action="{ attrs }">
               <v-btn
                  color="white"
                  text
                  v-bind="attrs"
                  @click="snackbar = false"
               >
                  Cerrar
               </v-btn>
            </template>
         </v-snackbar>

         <!-- TOOLBAR -->
         <v-toolbar flat color="white" class="pt-3">
            <v-toolbar-title style="font-weight: 500">
               Historial de notas
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <div style="width: 200px">
               <v-select
                  v-model="selectCiclo"
                  class="mt-5 mr-1"
                  :items="cmbCiclo"
                  color="primary"
                  label="Ciclo"
                  placeholder="Seleccionar..."
                  readonly
               >
               </v-select>
            </div>
            <div style="width: 250px">
               <v-text-field
                  v-model="carnetLocal"
                  v-mask="'####-AA-###'"
                  class="mt-5 ml-1 mr-1"
                  append-icon="fa-search"
                  color="primary"
                  label="Carnet"
               ></v-text-field>
            </div>
            <v-spacer></v-spacer>
            <v-btn
               color="primary"
               class="ml-1"
               width="100"
               @click="listarHistorico()"
            >
               Buscar
            </v-btn>
         </v-toolbar>
         <v-dialog v-model="dialogReporteLoading" persistent width="300">
            <v-card color="primary" dark class="pt-5 pb-2">
               <v-card-text style="color: white">
                  Consultando notas en base de datos web de Registro Académico
                  <v-progress-linear
                     indeterminate
                     color="white"
                     class="mb-0"
                  ></v-progress-linear>
               </v-card-text>
            </v-card>
         </v-dialog>
         <br />
         <v-flex>
            <v-layout wrap>
               <v-flex xs12 my-4>
                  <v-card :elevation="2">
                     <v-toolbar color="primary" dark>
                        <v-toolbar-title>Notas</v-toolbar-title>
                     </v-toolbar>
                     <!-- DATATABLE talonario -->
                     <v-data-table
                        v-if="verNuevo == 0"
                        :headers="headers"
                        :items="notas"
                        :search="search"
                        :hide-default-footer="true"
                        class="elevation-0"
                        ><!-- Si no hay datos, mostrar boton para listar de nuevo -->
                        <template slot="no-data">
                           <div v-if="notas.length == 0">
                              <v-container>
                                 <v-layout wrap align-center>
                                    <v-flex xs12 class="text-center">
                                       <v-btn color="primary" @click="reset"
                                          >Sin notas para el ciclo</v-btn
                                       >
                                    </v-flex>
                                 </v-layout>
                              </v-container>
                           </div>
                        </template>
                        <template #item.promedio="{ item }">
                           {{ getProm(item) }}
                        </template>
                        <template #item.asistencia="{ item }">
                           {{ item.asistencia + '%' }}
                        </template>
                     </v-data-table>
                  </v-card>
               </v-flex>
               <v-flex xs12>
                  <v-simple-table>
                     <template #default>
                        <thead>
                           <tr>
                              <th class="text-left">Aprobadas Carrera</th>
                              <th class="text-left">Reprobadas Carrera</th>
                              <th class="text-left">Aprobadas Total</th>
                              <th class="text-left">Reprobadas Total</th>
                              <th class="text-left">Promedio</th>
                              <th class="text-left">Cum</th>
                           </tr>
                        </thead>
                        <tbody>
                           <tr v-for="item in resumen" :key="item.cum">
                              <td>{{ item.aprobadasCarrera }}</td>
                              <td>{{ item.reprobadasCarrera }}</td>
                              <td>{{ item.aprobadasTotal }}</td>
                              <td>{{ item.reprobadasTotal }}</td>
                              <td>{{ item.promedio }}</td>
                              <td>{{ item.cum }}</td>
                           </tr>
                        </tbody>
                     </template>
                  </v-simple-table>
               </v-flex>
            </v-layout>
         </v-flex>
      </v-flex>
   </v-layout>
</template>

<script>
import axios from 'axios'
import formatters from '../mixins/formattersLib'
import { mask } from 'vue-the-mask'

export default {
   name: 'RecordAlu',
   directives: {
      mask
   },
   components: {},
   mixins: [formatters],
   props: {
      carnet: {
         type: String,
         default: ''
      },
      showDialog: {
         type: Boolean,
         default: false
      },
      all: {
         type: Boolean,
         default: true
      }
   },
   data: (vm) => ({
      // Variables
      dialogEditar: false,
      editedIndex: -1,
      headers: [
         {
            text: 'Código',
            value: 'materiaCodigo',
            width: 100,
            class: 'titulo'
         },
         {
            text: 'Materia',
            value: 'materia',
            width: 250,
            class: 'titulo'
         },
         {
            text: 'Sección',
            value: 'seccion',
            width: 120,
            align: 'center',
            class: 'titulo'
         },
         {
            text: 'Nota I',
            value: 'periodoA',
            width: 100,
            align: 'center',
            class: 'titulo'
         },
         {
            text: 'Asistencia I',
            value: 'asistenciaA',
            width: 150,
            align: 'center',
            class: 'titulo'
         },
         {
            text: 'Nota II',
            value: 'periodoB',
            width: 100,
            align: 'center',
            class: 'titulo'
         },
         {
            text: 'Asistencia II',
            value: 'asistenciaB',
            width: 150,
            align: 'center',
            class: 'titulo'
         },
         {
            text: 'Nota III',
            value: 'periodoC',
            width: 100,
            align: 'center',
            class: 'titulo'
         },
         {
            text: 'Asitencia III',
            value: 'asistenciaC',
            width: 150,
            align: 'center',
            class: 'titulo'
         },
         {
            text: 'Promedio',
            value: 'promedio',
            width: 120,
            align: 'center',
            class: 'titulo'
         }
      ],
      // Variables de formularios
      dialogReporteLoading: false,
      btnDetalleColor: 'primary white--text',
      talonario: [],
      talonarios: [],
      notas: [],
      detalleIcon: 'fa-plus',
      fecha: vm.toISOLocal(new Date()).substr(0, 10),
      radiosBusqueda: 'r-npe',
      search: '',
      // Variables detalles de talonario
      // Otros
      read: 0,
      result: [],
      snackColor: 'primary',
      snackbar: false,
      valida: 0,
      verNuevo: 0,
      snackText: '',
      dialogPrint: false,
      // ciclos
      selectCiclo: '',
      cmbCiclo: [],
      resumen: [],
      carnetLocal: ''

      // Reglas de validacion de formularios marti
   }),
   computed: {},
   watch: {
      carnet(val) {
         this.carnetLocal = val
      },
      showDialog(val) {
         if (val) {
            this.notas = []
            this.talonarios = []
            this.carnetLocal = this.carnet
            setTimeout(() => {
               this.listarHistorico()
            }, 500)
         }
      }
   },
   created() {
      this.listarComboBoxes()
   },
   methods: {
      focusBusqueda() {
         this.$refs.txtBusqueda.focus()
      },
      // Si no cargaron bien las talonarios en el datatable vuelve a listar
      reset() {
         this.listarComboBoxes()
      },
      // ------------------ LISTAR INFORMACION --------------------
      /* Metodos para listar informacion desde la API */

      // Listar las talonarios de un periodo y popula el datatable principal
      listarHistorico() {
         this.notas = []
         this.talonarios = []
         this.dialogReporteLoading = true
         //console.log(me.dialogReporteLoading)
         axios
            .get('api/AlumnoNotas?Carnet=' + this.carnetLocal)
            .then((response) => {
               this.notas = response.data
               axios
                  .get(
                     'api/SolicitudDeBeca/AlumnoResumenAcademico?Carnet=' +
                        this.carnetLocal
                  )
                  .then((response) => {
                     this.resumen = response.data
                  })
                  .catch((error) => {
                     console.log(error)
                  })
               this.dialogReporteLoading = false
            })
            .catch((error) => {
               console.log(error)
               this.dialogReporteLoading = false
            })
         axios
            .get(
               'api/Contrato/Cuotas?Carnet=' +
                  this.carnetLocal +
                  '&PeriodoLectivo=' +
                  this.selectCiclo
            )
            .then((response) => {
               this.talonarios = response.data.sort(this.dynamicSort('cuota'))
            })
            .catch((error) => {
               console.log(error)
            })
      },
      printTalonario() {
         this.dialogPrint = true
         //me.editarTalonario(item, true)
      },
      closeDialogPrint() {
         this.dialogPrint = false
         this.clear()
      },
      listarComboBoxes() {
         axios
            .get('api/Configuracion/cicloActual')
            .then((response) => {
               var cicloSplit = response.data.valor.split('-')
               if (cicloSplit[0] == 'I') {
                  this.cmbCiclo.push('II-' + (parseInt(cicloSplit[1]) - 1))
                  this.selectCiclo = 'II-' + (parseInt(cicloSplit[1]) - 1)
               } else {
                  this.cmbCiclo.push('I-' + cicloSplit[1])
                  this.selectCiclo = 'I-' + cicloSplit[1]
               }
            })
            .catch(() => {
               //console.log(error)
            })
      },
      getColorEstado(item) {
         if (
            this.toISOLocal(new Date()).substr(0, 10) >
               this.parseDate(item.fechaVencimiento) &&
            !item.facturado
         ) {
            return 'red'
         } else {
            if (item.facturado) {
               return 'green'
            } else {
               return 'blue'
            }
         }
      },
      getStatus(item) {
         if (
            this.toISOLocal(new Date()).substr(0, 10) >
            this.parseDate(item.fechaVencimiento)
         ) {
            return 'En mora'
         } else {
            return 'Pendiente'
         }
      },
      getProm(item) {
         let suma =
            Number(item.periodoA) +
            Number(item.periodoB) +
            Number(item.periodoC)
         if (suma > 0) {
            return (
               suma /
               (Number(item.numeroPeriodos) > 0
                  ? Number(item.numeroPeriodos)
                  : 1)
            ).toFixed(2)
         } else {
            return 0.0
         }
      }
   }
}
</script>
